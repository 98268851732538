//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'rendimiento-combustible',
  data() {
    return {
      selection: {
        model: null,
        year: null,
        transmission: null,
        motor: null
      },
      loading: {
        model: false,
        year: false,
        transmission: false,
        motor: false,
        table: false
      },
      isComplete: false
    }
  },
  computed: {
    modelsP() {
      return this.$store.getters['model-performances/items']
    },
    model() {
      return this.$store.getters['model-performances/item']
    },
    models() {
      return this.$store.getters['model-performances/models']
    },
    years() {
      return this.$store.getters['model-performances/years']
    },
    transmissions() {
      return this.$store.getters['model-performances/transmissions']
    },
    motors() {
      return this.$store.getters['model-performances/motors']
    }
  },
  methods: {
    async change(val) {
      if (val == 'complete') {
        this.loading.table = true
        this.isComplete = true
        await this.$store.dispatch('model-performances/get', {
          'filters[where][model][eq]': this.selection.model.model,
          'filters[where][year][eq]': this.selection.year.year,
          'filters[where][transmission][eq]': this.selection.transmission
            .transmission,
          'filters[where][motor][eq]': this.selection.motor.motor
        })
        this.loading.table = false
        console.log(this.loading.table)
        return null
      }
      this.isComplete = false
      let q = {
        attribute: val,
        model: this.selection.model.model
      }
      if (val == 'years') {
        this.selection.year = null
        this.selection.transmission = null
        this.selection.motor = null
        this.loading.year = true
      }
      if (val == 'transmissions') {
        q.year = this.selection.year.year
        this.selection.transmission = null
        this.selection.motor = null
        this.loading.transmission = true
      }
      if (val == 'motors') {
        q.year = this.selection.year.year
        q.transmission = this.selection.transmission.transmission
        this.selection.motor = null
        this.loading.motor = true
      }
      await this.$store.dispatch('model-performances/attribute', q)
      this.loading = {
        model: false,
        year: false,
        transmission: false,
        motor: false,
        table: false
      }
    },
    translateTransmission(val) {
      switch (val) {
        case 'TA':
          return 'Automática'
        case 'TM':
          return 'Manual'
        default:
          return val
      }
    }
  },
  preFetch({ store }) {
    return store.dispatch('model-performances/attribute', {
      attribute: 'models',
      limit: 1000
    })
  }
}
